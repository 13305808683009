<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-teaser">
        <BlockTeaser
          :items="allItems"
          :pinned-items="mappedItems"
          :show-load-more="showLoadMore"
          @load-more="loadMore"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    pinned: {
        type: Array,
        default: () => [],
    },

    items: {
        type: Array,
        default: () => [],
    },

    program: {
        type: Object,
        required: true,
        default: () => ({}),
    },
});

const rawItems = computed(() => props.pinned);
const program = computed(() => props.program);
const mappedItems = ref([]);
const allItems = ref([]);

// show only first 7 items and loadmore button
const pageSize = ref(7);
const page = ref(1);
const showLoadMore = ref(true);

// get articles from pinned items
const articlesUUIDS = computed(() => rawItems.value.map((item) => (
    item.article ? item.article : '')));
const articles = ref([]);

const storyblokApi = useStoryblokApi();
const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const getPinnedArticles = async (uuids) => {
    if (uuids.length === 0) return;

    try {
        const { data } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'news/news-article',
            version: 'published',
            by_uuids: uuids.join(','),
            resolve_relations: 'News.department,News.type',
            sort_by: 'first_published_at:desc',
            language,
        });

        articles.value = data.stories;

        if (articles.value.length === 0) return;

        rawItems.value.forEach((item) => {
            const article = articles.value.find(
                (fetchedArticle) => fetchedArticle.uuid === item.article,
            );

            if (article) {
                mappedItems.value.push({
                    article: {
                        title: article.content.title,
                        lead: article.content.lead,
                        url: article.default_full_slug,
                        date: article.first_published_at,
                        type: article.content.type.name,
                        department: {
                            name: article.content.department.translated_slugs?.find(
                                (slug) => slug.lang === currentLocale.value,
                            )?.name || article.content.department.name,
                            color_3: article.content.department.content.color_3,
                        },
                        image: {
                            ...article.content.image_video,
                            provider: 'storyblok',
                        },
                    },
                    image_position: item.image_position,
                    size: item.size,
                });
            }
        });
    } catch (e) {
        useSentryError(e);

        // if we can't fetch the articles, we just use the raw items
        mappedItems.value = rawItems.value;
    }
};

const filter = ref(null);
const filterStore = useFilterStore();

const getAllArticles = async () => {
    if (program.value === 'national') {
        filter.value = {
            country: {
                is: 'not_empty',
            },
        };
    } else if (program.value === 'international') {
        filter.value = {
            country: {
                is: 'empty',
            },
        };
    } else {
        filter.value = null;
    }

    if (isArrayNotEmpty(mappedItems.value)) {
        filter.value = {
            ...filter.value,
            title: {
                not_in: mappedItems.value.map((item) => item.article.title).toString(),
            },
        };
    }

    try {
        const { data, headers } = await storyblokApi.get('cdn/stories/', {
            starts_with: 'news/news-article',
            version: 'published',
            resolve_relations: 'News.department,News.type',
            per_page: pageSize.value,
            page: page.value,
            filter_query: filter.value,
            sort_by: 'first_published_at:desc',
            language,
        });

        const allTags = [];
        data?.stories?.forEach((article) => {
            allTags.push(article.content.tags);
        });

        filterStore.setCloudTags(allTags.flat());

        if (page.value === 1) {
            allItems.value = data.stories.map((article) => ({
                article: {
                    title: article.content.title,
                    lead: article.content.lead,
                    url: article.default_full_slug,
                    date: article.first_published_at,
                    type: article.content.type.name,
                    tags: article.content.tags,
                    department: {
                        name: article.content.department.translated_slugs?.find(
                            (slug) => slug.lang === currentLocale.value,
                        )?.name || article.content.department.name,
                        color_3: article.content.department.content.color_3,
                    },
                    image: {
                        ...article.content.image_video,
                        provider: 'storyblok',
                    },
                    hasVideoTeaser: article.content.activate_video_teaser,
                    textVideoTeaser: article.content.teaser_video,
                },
            }));

            if (allItems.value.length < parseInt(headers?.total, 10)) {
                showLoadMore.value = true;
            } else {
                showLoadMore.value = false;
            }
            return;
        }

        allItems.value = [...allItems.value, ...data.stories.map((article) => ({
            article: {
                title: article.content.title,
                lead: article.content.lead,
                url: article.default_full_slug,
                date: article.first_published_at,
                type: article.content.type.name,
                tags: article.content.tags,
                department: {
                    name: article.content.department.translated_slugs?.find(
                        (slug) => slug.lang === currentLocale.value,
                    )?.name || article.content.department.name,
                    color_3: article.content.department.content.color_3,
                },
                image: {
                    ...article.content.image_video,
                    provider: 'storyblok',
                },
                hasVideoTeaser: article.content.activate_video_teaser,
                textVideoTeaser: article.content.teaser_video,
            },
        }))];

        if (allItems.value.length === parseInt(headers?.total, 10)) {
            showLoadMore.value = false;
        }
    } catch (e) {
        useSentryError(e);

        // if we can't fetch the articles, we just use the raw items
        mappedItems.value = rawItems.value;
    }
};

const loadMore = () => {
    page.value += 1;
    getAllArticles();
};

onMounted(async () => {
    await getPinnedArticles(articlesUUIDS.value);
    await getAllArticles();
});

</script>

<style lang="scss" scoped>
.storyblok-teaser {
    @include grid-columns(4, 12);
    @include fluid('margin-bottom', 50px, 80px);
}
</style>
